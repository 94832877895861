import request from '@/utils/request';

import { setAppName } from "@/utils/common";
const appName = setAppName();
// const appName = "huanxinxiang-guangtong";
// 单笔支付
export const alipayNoSignSMSPay = ( data, params) => request({
  url: '/huanxinxiang-service/v1/alipay-no-sign-sms-pay/',
  method: 'post',
  headers: {
    "App-Name": appName,
  },
  data,
  params,
});

// 获取短信
export const code = (data) => request({
  url: '/huanxinxiang-service/v1/code/',
  method: 'post',
  headers: {
    "App-Name": appName,
  },
  data,
});
