<template>
  <div class="container">
    <img
      class="top"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/yunmobile_GuangTong/bg_11.jpg"
    />
    <div class="form">
      <van-field
        v-model="form.phone_number"
        type="tel"
        maxlength="11"
        placeholder="请输入您的手机号码"
      />

      <van-field
        v-model="form.phone_code"
        type="number"
        center
        maxlength="6"
        autocomplete="one-time-code"
        placeholder="请输入验证码"
        class="input-code"
      >
        <template slot="right-icon">
          <div class="code" v-show="sendAuthCode" @click="getAuthCode">
            获取验证码
          </div>
          <div class="code" v-show="!sendAuthCode">
            <span>{{ auth_time }}秒</span>重新获取
          </div>
        </template>
      </van-field>
      <img
        class="submit"
        @click="onSubmit"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/yunmobile_GuangTong/btn_11.png"
      />
    </div>
    <div >
      <img
        class="card"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/yunmobile_GuangTong/bg_33.jpg"
      />
    </div>
  </div>
</template>

<script>
import { code, alipayNoSignSMSPay } from "@/api/yunMobile_Guangtong.js";
export default {
  data() {
    return {
      form: {
        phone_number: "",
        phone_code: "",
      },
      sendAuthCode: true,
      auth_time: 0,
      state: false,
      visibility: false,
    };
  },
  methods: {
    // 验证码
    async getAuthCode() {
      let filterPhone = /^1[3456789]\d{9}$/;
      const { phone_number } = this.form;
      if (!filterPhone.test(phone_number)) {
        this.$dialog.alert({ message: "手机号错误" });
        return;
      }
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "加载中...",
      });
      const res = await code({ phone_number });
      this.$toast.clear();
      if (res.code != 0) {
        this.$dialog.alert({ message: res.message });
      } else {
        // 设置倒计时秒
        this.sendAuthCode = false;
        this.auth_time = 60;
        let auth_timetimer = setInterval(() => {
          this.auth_time--;
          if (this.auth_time <= 0) {
            this.sendAuthCode = true;
            clearInterval(auth_timetimer);
          }
        }, 1000);
      }
    },
    async onSubmit() {
      let filtercode = /^\d{6}$/;
      let filterPhone = /^1[3456789]\d{9}$/;
      const { phone_number, phone_code } = this.form;
      if (!phone_number) {
        this.$dialog.alert({ message: "请输入手机号" });
      } else if (!filterPhone.test(phone_number)) {
        this.$dialog.alert({ message: "请输入正确的手机号码" });
      } else if (!phone_code) {
        this.$dialog.alert({ message: "请输入验证码" });
      }else if (!filtercode.test(phone_code)) {
        this.$dialog.alert({ message: "请输入6位验证码" });
      } else {
        this.$toast.loading({
          duration: 0,
          forbidClick: true,
          mask: true,
          message: "加载中...",
        });
        const { from = "" } = this.$route.query;
        const res = await alipayNoSignSMSPay({
          phone_number,
          phone_code,
          item_name: "移动云手机会员",
        }, {
          order_channel_from: from,
        });
        this.$toast.clear();
        if (res.code != 0) {
          this.$toast({
            duration: 8000,
            message: res.message,
          });
        } else {
          const container = document.createElement("div");
          container.innerHTML = res.sign_str;
          document.body.appendChild(container);

          const form = container.querySelector('form[name="punchout_form"]');
          if (form) {
            form.submit();
            document.body.removeChild(container);
          } else {
            console.error("未能找到支付表单");
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background-color: #22a0fe;
  .top,
  .card {
    width: 100%;
  }

  .form {
    background: url("https://cdn.qiumo.net.cn/allsmarketmaterial/yunmobile_GuangTong/bg_22.jpg")
      center center/contain no-repeat;
    height: 604px;
    width: 750px;
    padding-top: 10px;
    box-sizing: border-box;
    overflow: hidden;
    ::v-deep .van-cell {
      width: 610px;
      height: 100px;
      background-color: #ffffff;
      border-radius: 50px;
      border: solid 2px #819cbe;
      margin: 100px auto 0;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 0 0 23px;

      &.input-code {
        margin-top: 20px;

        .code {
          width: 190px;
          height: 100px;
          text-align: center;
          line-height: 100px;
          color: #fff;
          font-size: 28px;
          font-weight: normal;
          background-image: linear-gradient(to right, #9bb3fd ,#66dcfe);
          border-left: solid 2px #819cbe;
        }
      }

      .van-field__body {
        height: 100%;

        input {
          height: 100%;
        }
      }

      &::after {
        border: none;
      }

      .van-field__control {
        color: #006dbf;
        font-weight: normal;
        &::-webkit-input-placeholder {
          font-size: 34px;
          font-weight: 40;
          color: #a4a9b7;
        }
      }
    }

    .submit {
      width: 572px;
      display: block;
      margin: 20px auto 0;
    }

    .checked {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 24px;
      font-weight: 400;
      padding: 10px 0 300px;

      ::v-deep .van-checkbox {
        width: auto;
        .van-checkbox__icon .van-icon{
          border: 1px solid #006dbf;
        }

        &__label {
          margin-left: 4px;
          color: #006dbf !important;

          .book {
            color: #fdff5d;
          }
        }
      }
    }
  }

}
</style>